import { cn } from "@/lib/utils.ts";
import React, { forwardRef, useEffect } from "react";
import { useControlField, useField } from "remix-validated-form";
import { useValidatorField } from "~zipdeal-ui/hooks/useValidatorField.tsx";
import { emptyNormalizeFn } from "~zipdeal-ui/utilities/stables.ts";

interface Props {
	name: string;
	formId: string;
	unhidden?: boolean;
	checkbox?: boolean;
	value?: string;
	afterChange?: Function;
	normalize?: Function;
	onChange?: Function;
}
export const ZipHiddenInput = React.memo(
	forwardRef<HTMLInputElement, Props>(
		(
			{
				name,
				formId,
				value = undefined,
				afterChange = undefined,
				onChange = undefined,
				unhidden = false,
				checkbox = false,
				normalize = emptyNormalizeFn,
			}: Props,
			ref,
		) => {
			const { error, getInputProps } = useField(name, {
				formId: formId || "undetermined-form",
			});
			const [_value, _setValue] = useControlField<string>(
				name,
				formId || "undetermined-form",
			);

			const {
				setValidatorValue,
				error: errorValidation,
				validatedInputEl,
			} = useValidatorField<string>(name, formId);

			/* This is for controlled inputs */
			useEffect(() => {
				if (value !== undefined) {
					_setValue(value);
					setValidatorValue(value);
					afterChange?.(value);
				}
			}, [_setValue, afterChange, setValidatorValue, value]);

			const checkboxProps = { type: "checkbox", checked: _value };
			const _inputProps = getInputProps({
				type: unhidden ? "text" : "hidden",
				id: name,
				"data-testid": name,
				value: _value ?? "",
				onChange: onChange
					? (event) => onChange({ event, _setValue })
					: (event) => {
							// event.preventDefault();
							const { value } = event.target;
							const normalizedValue = normalize(value, _value);
							_setValue(normalizedValue);
							setValidatorValue(normalizedValue);
							afterChange?.(normalizedValue);
							return normalizedValue;
						},
				...(checkbox ? checkboxProps : {}),
			} as any);

			return (
				<>
					<input
						type={unhidden ? "text" : "hidden"}
						ref={ref}
						form={formId}
						className={cn({
							hidden: !unhidden,
						})}
						{..._inputProps}
					/>
					{validatedInputEl}
				</>
			);
		},
	),
);
ZipHiddenInput.displayName = "ZipHiddenInput";
